/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useDialog } from '@/hooks/useDialog';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import {
  updateMask as updateMaskCPFOrCNPJ,
  unmask as unMaskCPFOrCNPJ,
} from '@/helpers/masks/cpfCnpj';
import { updateMask as updateMaskCEP, unmask as unMaskCEP } from '@/helpers/masks/cep';
import {
  updateMask as updateMaskMobilePhone,
  unmask as unMaskMobilePhone,
} from '@/helpers/masks/mobilePhone';
import { FormInputName } from '@/features/artist/components/RegisterContent';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { FormInputName as FormInputNameToFilter } from '@/features/artist/components/FilterContent';
import { colors } from '@/styles/colors';
import StatusType from '@/model/StatusType';
import User from '@/model/User';
import Address from '@/model/Address';
import { DeleteContent } from '@/features/contractor/components/DeleteContent';
import Artist from '@/model/Artist';
import Page from '@/model/Page';
import { ArtistContainer, ShouldShowModal, States } from './ui';

export const ArtistScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [currentPage, setCurrentPage] = useState<Page<Artist, Artist>>({
    page: 1,
    pageSize: 10,
    sort: 'name',
    order: 'DESC',
  });
  const [users, setUsers] = useState<User[]>([]);
  const [artist, setArtist] = useState<Artist>({ users: [] as User[] } as Artist);
  const [user, setUser] = useState<User>();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
    ShouldShowModal.registerArtist,
  );

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      name: '',
      document: '',
      telephone: '',
      email: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      number: '',
      complement: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
      telephone: [validators.required, validators.mobilePhone],
      email: [validators.required, validators.email],
    },
    formatters: {
      document: updateMaskCPFOrCNPJ,
      telephone: updateMaskMobilePhone,
      zipCode: updateMaskCEP,
    },
  });

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        resetForm();
        setArtist({ users: [] as User[] } as Artist);
      }, 500);
    }
  }, [visible]);

  const handleGetUsers = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<User[]>('/user/find');
      if (data) {
        setUsers(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnChangeUser = (userId: string): void => {
    const selected = users.find(item => item.id === userId);
    setUser(selected);
  };

  const handleAddUser = (): void => {
    if (user && artist) {
      const selected = users.filter(item => item.id === user.id)[0];
      const newUsers: User[] = [];
      if (artist.users && artist.users.length > 0) {
        artist.users.forEach(data => {
          newUsers.push(data);
        });
      }
      newUsers.push(selected);

      const newArtist = { ...artist, users: newUsers };
      setUser(undefined);
      setArtist(newArtist);
    }
  };

  const handleRemoveUser = (index: number): void => {
    if (artist) {
      const newUsers: User[] = [];
      if (artist.users && artist.users.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < artist.users.length; i++) {
          if (i !== index) {
            newUsers.push(artist.users[i]);
          }
        }
      }
      const newArtist = { ...artist, users: newUsers };
      setArtist(newArtist);
    }
  };

  const controllerAppendUser = {
    user,
    users,
    onChangeUser: handleOnChangeUser,
    addUser: handleAddUser,
    removeUser: handleRemoveUser,
  };

  const handleFetch = async (page: Page<Artist, Artist>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Artist, Artist>>('/artist/page', page);
      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };
  const handleOnChangeColorColumn = (status: StatusType): string =>
    ({
      0: colors.green,
      1: colors.red,
      2: colors.red,
    }[status] || colors.grey);

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    artist: artistSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    artist?: Artist;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);

    if (artistSelected && artistSelected.id && value !== ShouldShowModal.filter) {
      setArtist(artistSelected);
    }
  };

  const handleOnSave = async (): Promise<void> => {
    try {
      if (isFormValid()) {
        const payload = {
          id: artist.id,
          name: formData[FormInputName.name],
          document: unMaskCPFOrCNPJ(formData[FormInputName.document]),
          telephone: unMaskMobilePhone(formData[FormInputName.telephone]),
          email: formData[FormInputName.email],
          users: artist.users,
          status: artist.status,
        } as Artist;
        if (formData[FormInputName.zipCode] || artist.address?.id) {
          const address: Address = {
            id: artist?.address?.id,
            zipCode: unMaskCEP(formData[FormInputName.zipCode]),
            state: formData[FormInputName.state],
            city: formData[FormInputName.city],
            district: formData[FormInputName.district],
            street: formData[FormInputName.street],
            complement: formData[FormInputName.complement],
            number: formData[FormInputName.number],
          };
          payload.address = address;
        }

        setState(States.loading);
        await api.post('/artist', payload);
        onToggle();
        toast.success('Artista registrado com sucesso!');
        handleFetch(currentPage);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDelete = async (artistSelected: Artist): Promise<void> => {
    try {
      await api.delete(`/artist/${artistSelected.id}`);

      toast.success('Artista excluído com sucesso!');
      handleOnClose();
      handleFetch(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnShowDelete = (artistSelected: Artist): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(artistSelected),
        },
      ],
    });
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const payload = {} as Artist;
        if (formDataFilter[FormInputNameToFilter.filterSearch] === 'name') {
          payload.name = formDataFilter[FormInputNameToFilter.inputSearch];
        }
        onToggle();
        await handleFetch({
          ...currentPage,
          entity: payload,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const clearFilter = async (): Promise<void> => {
    resetFormFilter();
    await handleFetch({
      ...currentPage,
      entity: {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
    onToggle();
  };

  const handleOnPaginationChange = async (page: number): Promise<void> => {
    handleFetch({
      ...currentPage,
      page,
    });
  };

  useEffect(() => {
    if (artist?.id) {
      const statusBooleanString = {
        0: 'true',
        1: 'false',
        2: 'false',
      }[artist.status];

      onChangeFormInput(FormInputName.name)(artist.name);
      onChangeFormInput(FormInputName.document)(artist.document);
      onChangeFormInput(FormInputName.telephone)(artist.telephone);
      onChangeFormInput(FormInputName.email)(artist.email);

      if (artist.address) {
        onChangeFormInput(FormInputName.zipCode)(artist.address.zipCode);
        onChangeFormInput(FormInputName.state)(artist.address.state);
        onChangeFormInput(FormInputName.city)(artist.address.city);
        onChangeFormInput(FormInputName.district)(artist.address.district);
        onChangeFormInput(FormInputName.street)(artist.address.street);
        onChangeFormInput(FormInputName.number)(artist.address.number ?? '');
        onChangeFormInput(FormInputName.complement)(artist.address.complement ?? '');
      }
      onChangeFormInput(FormInputName.status)(statusBooleanString);
    }
  }, [artist]);

  useEffect(() => {
    handleFetch(currentPage);
    handleGetUsers();
  }, []);

  return (
    <ArtistContainer
      state={state}
      artist={artist}
      title={title}
      visible={visible}
      onToggle={onToggle}
      onPaginationChange={handleOnPaginationChange}
      shouldShowModal={shouldShowModal}
      onSave={handleOnSave}
      currentPage={currentPage}
      changeColorColumn={handleOnChangeColorColumn}
      onChangeFormInputFilter={onChangeFormInputFilter}
      onShouldShowModal={handleOnShouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onShowDelete={handleOnShowDelete}
      onFilter={handleOnFilter}
      clearFilter={clearFilter}
      controllerAppendUser={controllerAppendUser}
    />
  );
};
